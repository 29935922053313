/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
let t = 1e-6;
function e() {
  return t;
}
function n(e) {
  t = e;
}
const o = Math.random,
  a = Math.PI / 180,
  r = 180 / Math.PI;
function u(t) {
  return t * a;
}
function c(t) {
  return t * r;
}
function i(e, n) {
  return Math.abs(e - n) <= t * Math.max(1, Math.abs(e), Math.abs(n));
}
const s = Object.freeze(Object.defineProperty({
  __proto__: null,
  RANDOM: o,
  equals: i,
  getEpsilon: e,
  setEpsilon: n,
  toDegree: c,
  toRadian: u
}, Symbol.toStringTag, {
  value: "Module"
}));
export { o as RANDOM, s as c, i as equals, e as getEpsilon, n as setEpsilon, c as toDegree, u as toRadian };